import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 16 17" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path d="M10.8594 1H14.8594V5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M13.1457 8.5V14.7868C13.1457 15.0899 13.0252 15.3807 12.8109 15.5949C12.5966 15.8092 12.3059 15.9297 12.0028 15.9297H2.14481C1.8417 15.9297 1.55102 15.8092 1.33669 15.5949C1.12236 15.3807 1.00195 15.0899 1.00195 14.7868L1 4.07254C1 3.76944 1.12041 3.47875 1.33473 3.26442C1.54906 3.0501 1.83975 2.92969 2.14286 2.92969H3.85967H7.64539"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M14.8661 1L7.4375 8.42857" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </Svg>
  )
}

export default Icon
