import { API_URL } from './constants'
import { fetchDataFromAPIWithParams } from './helpers'

const ENDPOINT = 'user'

const fetchUserLeaderBoard = async (account: string) => {
  if (account) {
    try {
      const { data } = await fetchDataFromAPIWithParams(`${API_URL}/${ENDPOINT}`, { address: account })
      return data
    } catch (error) {
      console.error('error', error)
      return null
    }
  }
  return null
}

export default fetchUserLeaderBoard
