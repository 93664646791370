/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
// import styled from 'styled-components'
// import { baseColors, darkColors, lightColors } from '../../theme/colors'
import {
  Flex,
  // Box
} from '../Box'
import { Link } from '../Link'
import {
  StyledFooter,
  GridLayoutSocials,
  // StyledIconMobileContainer,
  // StyledList,
  // StyledListItem,
  // StyledText,
  // StyledSocialLinks,
  // StyledToolsContainer,
} from './styles'
import { FooterProps } from './types'
// import { ThemeSwitcher } from '../ThemeSwitcher'
// import LangSelector from '../LangSelector/LangSelector'
// import CakePrice from '../CakePrice/CakePrice'
import {
  // LogoWithTextIcon,
  // ArrowForwardIcon,
  TelegramIcon,
  TwitterIcon,
  DiscordIcon,
  GithubIcon,
  ChartSocialIcon,
  DocsSocialIcon,
} from '../Svg'
import { Text } from '../Text'
// import { Button } from '../Button'

const MenuItem: React.FC<FooterProps> = ({
  items,
  isDark,
  toggleTheme,
  currentLang,
  langs,
  setLang,
  cakePriceUsd,
  buyCakeLabel,
  ...props
}) => {
  return (
    <StyledFooter padding="12px 16px" {...props} justifyContent="space-between" alignItems="center" minHeight="150px">
      <Flex flexDirection="column">
        <Flex mb="8px">
          <img src="/images/logo_desktop.svg" alt="" />
        </Flex>
        <Text fontSize="18px" fontWeight="300">
          Trade crypto safe, fast, all-in-one on Soneium.
        </Text>
      </Flex>

      <Text fontSize="18px">ⓒ 2024 | SoneFinance | All rights reserved.</Text>

      <GridLayoutSocials>
        <Flex>
          <Link external href="https://x.com/SoneFinance">
            <TwitterIcon width="22px" mr="12px" />
            <Text fontSize="18px">Twitter</Text>
          </Link>
        </Flex>
        <Flex>
          <Link external href="https://discord.gg/fdQD5YXKAk">
            <DiscordIcon width="22px" mr="12px" />
            <Text fontSize="18px">Discord</Text>
          </Link>
        </Flex>
        <Flex>
          <Link external href="https://t.me/Sone_Finance">
            <TelegramIcon width="22px" mr="12px" />
            <Text fontSize="18px">Telegram</Text>
          </Link>
        </Flex>
        <Flex>
          <Link external href="#">
            <DocsSocialIcon width="22px" mr="12px" />
            <Text fontSize="18px">Docs</Text>
          </Link>
        </Flex>
      </GridLayoutSocials>
    </StyledFooter>
  )
}

export default MenuItem
