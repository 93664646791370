import React, { useContext } from 'react'
import { isMobile } from 'react-device-detect'
import { MenuContext } from '../../widgets/Menu/context'
import StyledMenuItem, { StyledMenuItemContainer } from './styles'
import { MenuItemProps } from './types'

const MenuItem: React.FC<MenuItemProps> = ({
  children,
  href,
  isActive = false,
  variant = 'default',
  statusColor,
  isComming,
  ...props
}) => {
  const { linkComponent } = useContext(MenuContext)
  const itemLinkProps: unknown = href
    ? {
        as: linkComponent,
        href,
      }
    : {
        as: 'div',
      }
  return (
    <StyledMenuItemContainer $isActive={isActive} $variant={variant} $isMobile={isMobile}>
      <StyledMenuItem
        {...itemLinkProps}
        $isActive={isActive}
        $variant={variant}
        $statusColor={statusColor}
        $isComming={isComming}
        {...props}
      >
        {children}
      </StyledMenuItem>
    </StyledMenuItemContainer>
  )
}

export default MenuItem
