import { createReducer, createAsyncThunk } from '@reduxjs/toolkit'
import fetchListLeaderBoard from './fetchListLeaderBoard'
import fetchUserLeaderBoard from './fetchUserLeaderBoard'

export const initialState = {
  userInfo: {},
  list: [],
}

export const fetchListLeaderBoardAsync = createAsyncThunk('leaderboard/fetchListLeaderBoard', async () => {
  const data = await fetchListLeaderBoard()
  return data
})

export const fetchUserLeaderBoardAsync = createAsyncThunk(
  'leaderboard/fetchUserLeaderBoard',
  async (account: string) => {
    const data = await fetchUserLeaderBoard(account)
    return data
  },
)

export default createReducer(initialState, (builder) =>
  builder
    .addCase(fetchListLeaderBoardAsync.fulfilled, (state, { payload }) => {
      state.list = payload
    })
    .addCase(fetchUserLeaderBoardAsync.fulfilled, (state, { payload }) => {
      state.userInfo = payload
    }),
)
