import throttle from 'lodash/throttle'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import BottomNav from '../../components/BottomNav'
import { Box } from '../../components/Box'
import Flex from '../../components/Box/Flex'
// import Footer from '../../components/Footer'
import MenuItems from '../../components/MenuItems/MenuItems'
// import { SubMenuItems } from '../../components/SubMenuItems'
import { useMatchBreakpoints } from '../../hooks'
import CakePrice from '../../components/CakePrice/CakePrice'
import Logo from './components/Logo'
import { MENU_HEIGHT, MOBILE_MENU_HEIGHT, TOP_BANNER_HEIGHT, TOP_BANNER_HEIGHT_MOBILE } from './config'
import { NavProps } from './types'
// import LangSelector from '../../components/LangSelector/LangSelector'
import { MenuContext } from './context'
import { SubMenuItems } from '../../components/SubMenuItems'
import { ChartSocialIcon, DocsSocialIcon, TelegramIcon, TwitterIcon } from '../../components/Svg'
import { Link } from '../../components/Link'
import Footer from '../../components/Footer'
import { Text } from '../../components/Text'
import AnimatedIconComponent from '../../components/Svg/AnimatedIconComponent'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background: url('/images/bg.svg') no-repeat, #fff5fa;
  background-size: cover;
  background-position: center;
  overflow-x: hidden;
  min-height: 100vh;
`

const StyledNav = styled.nav`
  position: relative;
  z-index: 9;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 100%;
  padding: 0 16px;
  background-color: transparent;
  transform: translate3d(0, 0, 0);
`

const FixedContainer = styled.div<{ showMenu: boolean; height: number }>`
  transition: top 0.2s;
  z-index: 20;
`

const BodyWrapper = styled(Box)`
  position: relative;
  padding-top: 36px;
`

const Inner = styled.div<{ isPushed: boolean; showMenu: boolean }>`
  flex-grow: 1;
  transition: margin-top 0.2s, margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(0, 0, 0);
  max-width: 100%;
  background: transparent;
  /* 
  ${({ theme }) => theme.mediaQueries.xxs} {
    min-height: 120vh;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    min-height: auto;
  } */
`

const StyledFlex = styled(Flex)`
  ${({ theme }) => theme.mediaQueries.sm} {
    gap: 5px;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    gap: 24px;
  }
`

const Menu: React.FC<NavProps> = ({
  linkComponent = 'a',
  userMenu,
  banner,
  globalMenu,
  isDark,
  toggleTheme,
  currentLang,
  setLang,
  cakePriceUsd,
  links,
  subLinks,
  footerLinks,
  activeItem,
  activeSubItem,
  langs,
  buyCakeLabel,
  children,
  decribe,
}) => {
  const { isMobile, isMd } = useMatchBreakpoints()
  const [showMenu, setShowMenu] = useState(true)
  const refPrevOffset = useRef(typeof window === 'undefined' ? 0 : window.pageYOffset)

  const topBannerHeight = isMobile ? TOP_BANNER_HEIGHT_MOBILE : TOP_BANNER_HEIGHT

  const totalTopMenuHeight = banner ? MENU_HEIGHT + topBannerHeight : MENU_HEIGHT

  useEffect(() => {
    const handleScroll = () => {
      const currentOffset = window.pageYOffset
      const isBottomOfPage = window.document.body.clientHeight === currentOffset + window.innerHeight
      const isTopOfPage = currentOffset === 0
      // Always show the menu when user reach the top
      if (isTopOfPage) {
        setShowMenu(true)
      }
      // Avoid triggering anything at the bottom because of layout shift
      else if (!isBottomOfPage) {
        if (currentOffset < refPrevOffset.current || currentOffset <= totalTopMenuHeight) {
          // Has scroll up
          setShowMenu(true)
        } else {
          // Has scroll down
          setShowMenu(false)
        }
      }
      refPrevOffset.current = currentOffset
    }
    const throttledHandleScroll = throttle(handleScroll, 200)

    window.addEventListener('scroll', throttledHandleScroll)
    return () => {
      window.removeEventListener('scroll', throttledHandleScroll)
    }
  }, [totalTopMenuHeight])

  // Find the home link if provided
  const homeLink = links.find((link) => link.label === 'Home')

  const subLinksWithoutMobile = subLinks?.filter((subLink) => !subLink.isMobileOnly)
  const subLinksMobileOnly = subLinks?.filter((subLink) => subLink.isMobileOnly)

  const [isBottomNavOpen, setIsBottomNavOpen] = useState(false)
  const bottomNavRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (bottomNavRef.current && !bottomNavRef.current.contains(event.target as Node)) {
        setIsBottomNavOpen(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [bottomNavRef])

  const decribeIcon = decribe?.icon

  return (
    <MenuContext.Provider value={{ linkComponent }}>
      <Wrapper>
        {!isMobile ? (
          <FixedContainer showMenu={showMenu} height="auto">
            {/* {banner && <TopBannerContainer height={topBannerHeight}>{banner}</TopBannerContainer>} */}

            <StyledNav>
              <Flex width="100%">
                <Logo isDark={isDark} href={homeLink?.href ?? '/swap'} />
                {!isMobile && (
                  <MenuItems
                    justifyContent="center"
                    items={links}
                    activeItem={activeItem}
                    activeSubItem={activeSubItem}
                  />
                )}

                <Flex mr="20px" alignItems="center" justifyContent="flex-end" width="100%">
                  {globalMenu} {userMenu}
                </Flex>
              </Flex>
            </StyledNav>
          </FixedContainer>
        ) : null}

        {isMobile && (
          <FixedContainer showMenu={showMenu} height="auto">
            <StyledNav width="100%">
              <Flex width="100%" justifyContent="space-between">
                <Logo isDark={isDark} href={homeLink?.href ?? '/swap'} />
                {!isMobile && (
                  <MenuItems mt="20px" items={links} activeItem={activeItem} activeSubItem={activeSubItem} />
                )}
                <Flex>
                  {globalMenu} {userMenu}
                </Flex>
              </Flex>
            </StyledNav>
          </FixedContainer>
        )}

        <Flex width="100%" flexDirection="column" minHeight="calc(100vh + 150px)" justifyContent="space-between">
          {/* {!isMobile && (
            <>
              <Flex width="100%">
                {subLinks && (
                  <Flex justifyContent="space-around">
                    {!isMobile && <SubMenuItems items={subLinksWithoutMobile} activeItem={activeSubItem} />}
                    {subLinksMobileOnly?.length > 0 && (
                      <SubMenuItems
                        items={subLinksMobileOnly}
                        mt={`${totalTopMenuHeight + 1}px`}
                        activeItem={activeSubItem}
                        isMobileOnly
                      />
                    )}
                  </Flex>
                )}
                <Flex mr="20px" alignItems="center" justifyContent="flex-end" width="100%">
                  {globalMenu} {userMenu}
                </Flex>
              </Flex>
            </>
          )} */}
          {isMobile && <SubMenuItems items={subLinksWithoutMobile} activeItem={activeSubItem} />}

          <BodyWrapper>
            <Inner isPushed={false} showMenu={showMenu}>
              {children}
            </Inner>
          </BodyWrapper>

          <Footer
            items={footerLinks}
            isDark={isDark}
            toggleTheme={toggleTheme}
            langs={langs}
            setLang={setLang}
            currentLang={currentLang}
            cakePriceUsd={cakePriceUsd}
            buyCakeLabel={buyCakeLabel}
            mb={[`${MOBILE_MENU_HEIGHT}px`, null, '0px']}
          />
        </Flex>
        {isMobile && <BottomNav items={links} activeItem={activeItem} activeSubItem={activeSubItem} />}
      </Wrapper>
    </MenuContext.Provider>
  )
}

export default Menu
