import { Fragment } from 'react'
import { FlexGap } from 'components/Layout/Flex'
import { Text } from 'packages/uikit'
import TaskItem from './Task.Item'
import { INIT_TASK_LIST } from './constants'

const TaskQuests = () => {
  return (
    <>
      {/* <FlexGap flexDirection="column" gap="8px">
        {INIT_TASK_LIST.map((task) => (
          <Fragment key={task.id}>
            <TaskItem type={task.type} title={task.title} />
          </Fragment>
        ))}
      </FlexGap> */}
      <Text textAlign="center" fontWeight="700">
        Comming soon
      </Text>
    </>
  )
}

export default TaskQuests
