import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 20 20" {...props}>
      <path d="M9.08594 12.7507H10.9193V14.584H9.08594V12.7507ZM9.08594 5.41732H10.9193V10.9173H9.08594V5.41732ZM9.99344 0.833984C4.93344 0.833984 0.835938 4.94065 0.835938 10.0007C0.835938 15.0607 4.93344 19.1673 9.99344 19.1673C15.0626 19.1673 19.1693 15.0607 19.1693 10.0007C19.1693 4.94065 15.0626 0.833984 9.99344 0.833984ZM10.0026 17.334C5.95094 17.334 2.66927 14.0523 2.66927 10.0007C2.66927 5.94898 5.95094 2.66732 10.0026 2.66732C14.0543 2.66732 17.3359 5.94898 17.3359 10.0007C17.3359 14.0523 14.0543 17.334 10.0026 17.334Z" />
    </Svg>
  )
}

export default Icon
