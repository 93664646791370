import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 23 19">
      <path
        d="M7.59951 18.3371C15.9123 18.3371 20.4591 11.5371 20.4591 5.64029C20.4591 5.44715 20.4591 5.25488 20.4458 5.06348C21.3304 4.43178 22.0939 3.64962 22.7007 2.75363C21.8759 3.11468 21.0009 3.35135 20.1048 3.45572C21.0484 2.89809 21.7545 2.02093 22.0918 0.987529C21.2047 1.50736 20.2341 1.87368 19.2219 2.07068C18.5405 1.35526 17.6392 0.881535 16.6577 0.722799C15.6761 0.564063 14.6688 0.72917 13.7919 1.19257C12.9149 1.65597 12.217 2.39183 11.8063 3.28628C11.3956 4.18072 11.2949 5.18389 11.5198 6.14054C9.72288 6.05154 7.96504 5.59047 6.36032 4.78726C4.75561 3.98404 3.3399 2.85663 2.20508 1.47821C1.62713 2.46058 1.45011 3.62351 1.71008 4.73022C1.97004 5.83692 2.64744 6.8042 3.60436 7.4351C2.88507 7.41429 2.18145 7.2227 1.55303 6.87656V6.93311C1.55331 7.96338 1.91453 8.96184 2.57542 9.75913C3.2363 10.5564 4.15616 11.1035 5.17898 11.3075C4.5136 11.4867 3.81546 11.5129 3.13822 11.384C3.42715 12.2706 3.98945 13.0459 4.74651 13.6016C5.50358 14.1572 6.41757 14.4654 7.36071 14.483C6.42364 15.2102 5.35054 15.7479 4.2028 16.0653C3.05506 16.3827 1.8552 16.4735 0.671875 16.3326C2.73876 17.6421 5.14366 18.3368 7.59951 18.3336"
        fill="white"
      />
    </Svg>
  )
}

export default Icon
