import styled from 'styled-components'
import { Box } from '../Box'

const StyledBottomNav = styled(Box)`
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 5px 8px;
  background: white;
  padding-bottom: env(safe-area-inset-bottom);
  html[data-useragent*='TokenPocket_iOS'] & {
    padding-bottom: 45px;
  }
  z-index: 20;
  min-height: 60px;

  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(95px, 1fr));
  grid-gap: 5px; */
  display: flex;
  
`

export default StyledBottomNav
