import React from 'react'
import styled from 'styled-components'
import Flex from '../../components/Box/Flex'
import { Box } from '../../components/Box'
import { ArrowBackIcon, CloseIcon } from '../../components/Svg'
import { IconButton } from '../../components/Button'
import { ModalProps } from './types'

export const ModalHeader = styled.div<{ background?: string }>`
  align-items: center;
  background: transparent;
  display: flex;
  position: relative;

  ${({ theme }) => theme.mediaQueries.xxs} {
    padding: 12px;
  }

  ${({ theme }) => theme.mediaQueries.xs} {
    padding: 12px 24px;
  }
`

export const ModalTitle = styled(Flex)`
  margin-top: 12px;
  align-items: center;
  flex: 1;
`

export const ModalBody = styled(Flex)`
  flex-direction: column;
  max-height: 90vh;
  overflow-y: auto;

  /* ${({ theme }) => theme.mediaQueries.xxs} {
    padding: 10px;
  }

  ${({ theme }) => theme.mediaQueries.xs} {
    padding: 24px;
  } */
`

export const ClosePosition = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`

export const ModalCloseButton: React.FC<{ onDismiss: ModalProps['onDismiss'] }> = ({ onDismiss }) => {
  return (
    <IconButton
      variant="text"
      onClick={onDismiss}
      aria-label="Close the dialog"
      style={{ width: 'auto', height: 'auto' }}
    >
      <CloseIcon color="var(--white)" width="16px" />
    </IconButton>
  )
}

export const ModalBackButton: React.FC<{ onBack: ModalProps['onBack'] }> = ({ onBack }) => {
  return (
    <IconButton variant="text" onClick={onBack} area-label="go back" mr="8px" style={{ width: 'auto', height: 'auto' }}>
      <ArrowBackIcon color="var(--white)" />
    </IconButton>
  )
}

export const ModalContainer = styled(Box)`
  overflow: hidden;
  background: var(--user-info-modal);
  border: 3px solid var(--primary);
  border-radius: 10px;
  width: 100%;
  max-height: 100vh;
  z-index: ${({ theme }) => theme.zIndices.modal};
`
