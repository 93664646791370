const variants = {
  warning: {
    background: 'transparent',
    borderColor: 'warning',
    iconColor: 'warning',
  },
  danger: {
    background: 'transparent',
    borderColor: 'failure',
    iconColor: 'var(--black)',
  },
  success: {
    background: 'transparent',
    borderColor: 'success',
    iconColor: 'var(--black)',
  },
}

export default variants
