import { Text } from 'packages/uikit'

const TaskLeaderboard = () => {
  return (
    <>
      <Text textAlign="center" fontWeight="700">
        Comming soon
      </Text>
    </>
  )
}

export default TaskLeaderboard
