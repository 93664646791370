import React, { useContext } from 'react'
import { MenuContext } from '../../widgets/Menu/context'
import { Flex } from '../Box'
import AnimatedIconComponent from '../Svg/AnimatedIconComponent'
import { StyledBottomNavItem, StyledBottomNavText, Soon } from './styles'
import { BottomNavItemProps } from './types'

const BottomNavItem: React.FC<BottomNavItemProps> = ({
  label,
  icon,
  fillIcon,
  href,
  showItemsOnMobile = false,
  isActive = false,
  commingSoon,
  ...props
}) => {
  const { linkComponent } = useContext(MenuContext)
  const bottomNavItemContent = (
    <Flex
      style={{
        width: '60px',
        background: isActive ? '#FDCEE5' : 'transparent',
        padding: '4px 8px',
        borderBottom: isActive ? '2px solid var(--primary)' : '0',
        borderStartEndRadius: isActive ? '8px' : '0',
        borderTopLeftRadius: isActive ? '8px' : '0',
      }}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      position="relative"
    >
      {icon && <AnimatedIconComponent icon={icon} fillIcon={fillIcon} height="22px" width="21px" isActive={isActive} />}
      <StyledBottomNavText
        color={isActive ? 'var(--primary)' : 'black'}
        fontWeight={isActive ? '600' : '400'}
        fontSize={['8px', , , '16px']}
      >
        {label}
      </StyledBottomNavText>
      {commingSoon ? <Soon fontSize="8px">Soon</Soon> : null}
    </Flex>
  )

  return showItemsOnMobile ? (
    <StyledBottomNavItem commingSoon={commingSoon} type="button" {...props}>
      {bottomNavItemContent}
    </StyledBottomNavItem>
  ) : (
    <StyledBottomNavItem commingSoon={commingSoon} as={linkComponent} href={href} {...props}>
      {bottomNavItemContent}
    </StyledBottomNavItem>
  )
}

export default BottomNavItem
