import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => (
  <Svg viewBox="0 0 18 18" {...props}>
    <g clipPath="url(#clip0_5963_5239)">
      <path
        d="M16.0747 8.87115L7.84613 0.642578L0.903281 9.89972C0.7196 10.1462 0.630282 10.4503 0.651509 10.7569C0.672737 11.0635 0.803109 11.3524 1.019 11.5711L5.14613 15.6983C5.36414 15.9108 5.65048 16.0391 5.95421 16.0603C6.25793 16.0814 6.55931 15.9943 6.80471 15.814L16.0747 8.87115Z"
        fill="#C17CCF"
      />
      <path
        d="M14.5392 16.8009C14.0415 16.2249 14.2023 15.3628 14.4915 14.6587C14.6998 14.1513 14.9614 13.6824 15.2734 13.1469C15.3191 13.0587 15.3884 12.9849 15.4735 12.9339C15.5586 12.8827 15.6563 12.8563 15.7557 12.8575C15.8537 12.8558 15.9501 12.8822 16.0337 12.9334C16.1173 12.9847 16.1844 13.0587 16.2272 13.1469C16.5448 13.6829 16.8104 14.153 17.0207 14.6629C17.31 15.364 17.4693 16.2225 16.975 16.7977C16.9487 16.8283 16.9211 16.858 16.8924 16.8867C16.5909 17.1882 16.1821 17.3576 15.7557 17.3576C15.3292 17.3576 14.9204 17.1882 14.6189 16.8867C14.5912 16.859 14.5646 16.8304 14.5392 16.8009Z"
        fill="#C17CCF"
      />
    </g>
    <defs>
      <clipPath id="clip0_5963_5239">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </Svg>
)

export default Icon
