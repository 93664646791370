import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 13">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5091 1.47731C16.1668 2.11372 16.1668 3.14554 15.5091 3.78195L7.5089 11.5227C6.85116 12.1591 5.78476 12.1591 5.12702 11.5227L1.54799 8.05973C0.890253 7.42332 0.890253 6.3915 1.54799 5.75508C2.20573 5.11868 3.27214 5.11868 3.92988 5.75508L6.31796 8.06573L13.1272 1.47731C13.7849 0.840897 14.8513 0.840897 15.5091 1.47731Z"
        fill="#14F194"
      />
      <path
        d="M15.5091 1.47731C16.1668 2.11372 16.1668 3.14554 15.5091 3.78195L7.5089 11.5227C6.85116 12.1591 5.78476 12.1591 5.12702 11.5227L1.54799 8.05973C0.890253 7.42332 0.890253 6.3915 1.54799 5.75508C2.20573 5.11868 3.27214 5.11868 3.92988 5.75508L6.31796 8.06573L13.1272 1.47731C13.7849 0.840897 14.8513 0.840897 15.5091 1.47731Z"
        fill="#14F194"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5091 1.47731C16.1668 2.11372 16.1668 3.14554 15.5091 3.78195L7.5089 11.5227C6.85116 12.1591 5.78476 12.1591 5.12702 11.5227L1.54799 8.05973C0.890253 7.42332 0.890253 6.3915 1.54799 5.75508C2.20573 5.11868 3.27214 5.11868 3.92988 5.75508L6.31796 8.06573L13.1272 1.47731C13.7849 0.840897 14.8513 0.840897 15.5091 1.47731Z"
        stroke="black"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5091 1.47731C16.1668 2.11372 16.1668 3.14554 15.5091 3.78195L7.5089 11.5227C6.85116 12.1591 5.78476 12.1591 5.12702 11.5227L1.54799 8.05973C0.890253 7.42332 0.890253 6.3915 1.54799 5.75508C2.20573 5.11868 3.27214 5.11868 3.92988 5.75508L6.31796 8.06573L13.1272 1.47731C13.7849 0.840897 14.8513 0.840897 15.5091 1.47731Z"
        stroke="black"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default Icon
