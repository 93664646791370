import { API_URL } from './constants'
import { fetchListFromAPI, mergeArrays } from './helpers'

const fetchListLeaderBoard = async () => {
  try {
    const { data } = await fetchListFromAPI(`${API_URL}`)
    const listLeaderBoard = mergeArrays(data.items)

    return listLeaderBoard
  } catch (error) {
    console.error('error', error)
    return null
  }
}

export default fetchListLeaderBoard
