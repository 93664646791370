import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none" viewBox="0 0 24 25">
      <path stroke="#0D0D0D" strokeLinecap="square" strokeWidth="1.5" d="M3 3.252H10V10.251999999999999H3z" />
      <path stroke="#0D0D0D" strokeLinecap="square" strokeWidth="1.5" d="M14 3.252H21V10.251999999999999H14z" />
      <path stroke="#0D0D0D" strokeLinecap="square" strokeWidth="1.5" d="M3 14.252H10V21.252000000000002H3z" />
      <path stroke="#0D0D0D" strokeLinecap="square" strokeWidth="1.5" d="M14 14.252H21V21.252000000000002H14z" />
    </Svg>
  )
}

export default Icon
