import { Colors } from './types'

export const baseColors = {
  failure: 'rgba(255, 70, 70, 1)',
  primary: 'rgba(193, 124, 207, 1)',
  primaryDark: 'rgba(121, 43, 151, 1)',
  blue: 'rgba(24, 110, 183, 1)',
  secondary: '#7645D9',
  success: 'rgba(20, 241, 148, 1)',
  warning: '#FFB237',
}

export const additionalColors = {
  binance: '#F0B90B',
  overlay: '#452a7a',
  gold: '#FFC700',
  silver: '#B2B2B2',
  bronze: '#E7974D',
}

export const stakeColors = {
  flexible: 'rgba(75, 151, 63, 1)',
}

export const lightColors: Colors = {
  ...baseColors,
  ...additionalColors,
  ...stakeColors,
  background: '#FAF9FA',
  backgroundDisabled: '#cccccc',
  backgroundAlt: '#FFFFFF',
  backgroundAlt2: '#FFFFFF',
  cardBorder: '#E7E3EB',
  contrast: '#191326',
  dropdown: '#F6F6F6',
  dropdownDeep: '#EEEEEE',
  invertedContrast: '#FFFFFF',
  input: '#FAF1EA',
  inputSecondary: '#d7caec',
  tertiary: '#EFF4F5',
  text: '#432645',
  textDisabled: '#F988BF',
  textSubtle: '#432645',
  disabled: '#E9EAEB',
  gradients: {
    bubblegum: 'linear-gradient(180deg, #E3CCCB -34.7%, #F9F1EA 79.3%)',
    inverseBubblegum: 'linear-gradient(139.73deg, #F3EFFF 0%, #E5FDFF 100%)',
    cardHeader: 'linear-gradient(111.68deg, #F2ECF2 0%, #E8F2F6 100%)',
    blue: 'linear-gradient(180deg, #A7E8F1 0%, #94E1F2 100%)',
    violet: 'linear-gradient(180deg, #E2C9FB 0%, #CDB8FA 100%)',
    violetAlt: 'linear-gradient(180deg, #CBD7EF 0%, #9A9FD0 100%)',
    gold: 'linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)',
    button: 'linear-gradient(180deg, #C17CCF 0%, #792B97 100%)',
  },
  toggle: '#756072',
  black: '#000000',
  pink: 'var(--primary)',
  pinkWhite: '#ffffff',
  pinkDis: '#FDCEE5',
  pinkLight: '#fff5fa',
}

export const darkColors: Colors = {
  ...baseColors,
  ...additionalColors,
  ...stakeColors,
  secondary: '#9A6AFF',
  background: '#08060B',
  backgroundDisabled: '#cccccc',
  backgroundAlt: '#FFFFFF',
  backgroundAlt2: '#FFFFFF',
  cardBorder: '#383241',
  contrast: '#FFFFFF',
  dropdown: '#1E1D20',
  dropdownDeep: '#100C18',
  invertedContrast: '#191326',
  input: '#FAF1EA',
  inputSecondary: '#262130',
  primaryDark: '#0098A1',
  tertiary: '#353547',
  text: '#432645',
  textDisabled: '#F988BF',
  textSubtle: '#432645',
  disabled: '#524B63',
  gradients: {
    bubblegum: 'linear-gradient(180deg, #E3CCCB -34.7%, #F9F1EA 79.3%)',
    inverseBubblegum: 'linear-gradient(139.73deg, #3D2A54 0%, #313D5C 100%)',
    cardHeader: 'linear-gradient(166.77deg, #3B4155 0%, #3A3045 100%)',
    blue: 'linear-gradient(180deg, #00707F 0%, #19778C 100%)',
    violet: 'linear-gradient(180deg, #6C4999 0%, #6D4DB2 100%)',
    violetAlt: 'linear-gradient(180deg, #434575 0%, #66578D 100%)',
    gold: 'linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)',
    button: 'linear-gradient(180deg, #C17CCF 0%, #792B97 100%)',
  },
  toggle: '#756072',
  black: '#000000',
  pink: 'var(--primary)',
  pinkWhite: '#ffffff',
  pinkDis: '#FDCEE5',
  pinkLight: '#fff5fa',
}
