import { BlockIcon, CheckmarkCircleIcon, Flex, Link, OpenNewIcon, Text, RefreshIcon } from 'packages/uikit'
import styled from 'styled-components'
import { TransactionDetails } from 'state/transactions/reducer'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { getBscScanLink } from 'utils'

interface TransactionRowProps {
  txn: TransactionDetails
}

const TxnIcon = styled(Flex)`
  align-items: center;
  flex: none;
  width: 24px;
`

const Summary = styled(Text).attrs({ fontSize: ['16px'] })`
  flex: 1;
  padding: 0 8px;
  color: var(--black);
`

const TxnLink = styled(Link)`
  align-items: center;
  color: white;
  display: flex;
  margin-bottom: 16px;
  width: 100%;

  &:hover {
    text-decoration: none;
  }
`

const renderIcon = (txn: TransactionDetails) => {
  if (!txn.receipt) {
    return <RefreshIcon spin width="24px" />
  }

  return txn.receipt?.status === 1 || typeof txn.receipt?.status === 'undefined' ? (
    <Flex
      borderRadius="50%"
      border="1px solid var(--success)"
      width="20px"
      height="20px"
      justifyContent="center"
      alignItems="center"
    >
      <CheckmarkCircleIcon color="success" width="20px" style={{ transform: 'translate(3px, -3px)' }} />
    </Flex>
  ) : (
    <BlockIcon color="failure" width="24px" />
  )
}

const TransactionRow: React.FC<TransactionRowProps> = ({ txn }) => {
  const { chainId } = useActiveWeb3React()

  if (!txn) {
    return null
  }

  return (
    <TxnLink href={getBscScanLink(txn.hash, 'transaction', chainId)} external>
      <Summary>{txn.summary ?? txn.hash}</Summary>
      <TxnIcon>{renderIcon(txn)}</TxnIcon>
      {/* <TxnIcon>
        <OpenNewIcon width="24px"  />
      </TxnIcon> */}
    </TxnLink>
  )
}

export default TransactionRow
