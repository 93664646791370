import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 15 15" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.647767 0.647767C1.51146 -0.207109 2.90394 -0.207109 3.76763 0.647767L5.32315 2.20329L6.87867 3.75881C7.22239 4.10253 7.77761 4.10253 8.12133 3.75881L11.2324 0.647767C12.0961 -0.215922 13.4885 -0.215922 14.3522 0.647767C15.2159 1.51146 15.2159 2.90394 14.3522 3.76763L11.2412 6.87867C10.8975 7.22239 10.8975 7.77761 11.2412 8.12133L14.3522 11.2324C15.2159 12.0961 15.2159 13.4885 14.3522 14.3522C13.4885 15.2159 12.0961 15.2159 11.2324 14.3522L8.12133 11.2412C7.77761 10.8975 7.22239 10.8975 6.87867 11.2412L3.76763 14.3522C2.90394 15.2159 1.51146 15.2159 0.647767 14.3522C-0.215922 13.4885 -0.215922 12.0961 0.647767 11.2324L3.75881 8.12133C4.10253 7.77761 4.10253 7.22239 3.75881 6.87867L0.647767 3.76763C-0.207109 2.90394 -0.207109 1.51146 0.647767 0.647767Z"
      />
    </Svg>
  )
}

export default Icon
