import { useFastRefreshEffect } from 'hooks/useRefreshEffect'
import { useAppDispatch } from 'state'
import { fetchListLeaderBoardAsync } from 'state/leaderBoard/reducer'

export const useGetListLeaderBoard = () => {
  const dispatch = useAppDispatch()

  useFastRefreshEffect(() => {
    dispatch(fetchListLeaderBoardAsync())
  }, [dispatch])
}
