import { Box, ChevronDownIcon, Flex, UserMenuItem, UserMenuProps } from 'packages/uikit'
import React, { useEffect, useState } from 'react'
import { usePopper } from 'react-popper'
import styled from 'styled-components'

export const StyledUserMenu = styled(Flex)`
  align-items: center;
  background-color: var(--user-info-modal);
  border-radius: 8px;
  box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: inline-flex;
  height: 36px;
  padding: 0 16px;
  position: relative;

  &:hover {
    opacity: 0.65;
  }
`

export const LabelText = styled.div`
  display: none;
  color: var(--white);

  ${({ theme }) => theme.mediaQueries.sm} {
    display: block;
    margin-left: 8px;
    margin-right: 4px;
  }
`

const Menu = styled.div<{ isOpen: boolean }>`
  background-color: #fff5fa;
  border-radius: 10px;
  pointer-events: auto;
  width: 180px;
  visibility: visible;
  z-index: 1001;

  ${({ isOpen }) =>
    !isOpen &&
    `
    pointer-events: none;
    visibility: hidden;
  `}

  ${UserMenuItem}:first-child {
    border-radius: 8px 8px 0 0;
  }

  ${UserMenuItem}:last-child {
    border-radius: 0 0 8px 8px;
  }
`

const Wrapper: React.FC<UserMenuProps> = ({ account, text, avatarSrc, variant = 'default', children, ...props }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [targetRef, setTargetRef] = useState<HTMLDivElement | null>(null)
  const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null)
  const accountEllipsis = account ? `${account.substring(0, 2)}...${account.substring(account.length - 4)}` : null
  const { styles, attributes } = usePopper(targetRef, tooltipRef, {
    strategy: 'fixed',
    placement: 'bottom-end',
    modifiers: [{ name: 'offset', options: { offset: [0, 0] } }],
  })

  // useEffect(() => {
  //   const showDropdownMenu = () => {
  //     setIsOpen(true)
  //   }

  //   const hideDropdownMenu = (evt: MouseEvent | TouchEvent) => {
  //     const target = evt.target as Node
  //     if (target && !tooltipRef?.contains(target)) {
  //       setIsOpen(false)
  //       evt.stopPropagation()
  //     }
  //   }

  //   targetRef?.addEventListener('mouseenter', showDropdownMenu)
  //   targetRef?.addEventListener('mouseleave', hideDropdownMenu)

  //   return () => {
  //     targetRef?.removeEventListener('mouseenter', showDropdownMenu)
  //     targetRef?.removeEventListener('mouseleave', hideDropdownMenu)
  //   }
  // }, [targetRef, tooltipRef, setIsOpen])

  return (
    <Flex alignItems="center" height="100%" ref={setTargetRef} {...props}>
      <StyledUserMenu
        onClick={() => {
          setIsOpen((s) => !s)
        }}
      >
        <img width={20} src="/images/network-logo.svg" alt="" />
        <LabelText title={text || account}>{text || accountEllipsis}</LabelText>
      </StyledUserMenu>
      <Menu style={styles.popper} ref={setTooltipRef} {...attributes.popper} isOpen={isOpen}>
        <Box onClick={() => setIsOpen(false)}>{children?.({ isOpen })}</Box>
      </Menu>
    </Flex>
  )
}

export default Wrapper
