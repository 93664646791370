import { useEffect, useState, memo } from 'react'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import {
  Flex,
  // LogoutIcon,
  // RefreshIcon,
  // useModal,
  UserMenu as UIKitUserMenu,
  // UserMenuItem,
  UserMenuVariant,
  Box,
  Text,
  LinkExternal,
  Button,
  Skeleton,
  CloseIcon,
  IconButton,
  // ArrowForwardIcon,
} from 'packages/uikit'
// import Trans from 'components/Trans'
import useAuth from 'hooks/useAuth'
// import { useRouter } from 'next/router'
// import { useProfile } from 'state/profile/hooks'
import { usePendingTransactions } from 'state/transactions/hooks'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { useTranslation } from 'contexts/Localization'
// import { nftsBaseUrl } from 'views/Nft/market/constants'
import { getBscScanLink } from 'utils'
import { FlexGap } from 'components/Layout/Flex'
import useTokenBalance, { useGetBnbBalance } from 'hooks/useTokenBalance'
import { formatBigNumber, getFullDisplayBalance } from 'utils/formatBalance'
import { FetchStatus } from 'config/constants/types'
import tokens from 'config/constants/tokens'
import { ETHER } from '@sonefi-dex/sdk'
import { CurrencyLogo } from 'components/Logo'
// import { WalletView } from './WalletModal'
// import ProfileUserMenuItem from './ProfileUserMenuItem'
// import WalletUserMenuItem from './WalletUserMenuItem'
import { NETWORK_CONFIG } from 'utils/wallet'
import { CopyButton } from '../../CopyButton'
import WalletTransactions from './WalletTransactions'
import UserMenuWrapper from './UserMenuWrapper'
import TaskLeaderboard from './Task.Leaderboard'
import TaskQuests from './Task.Quests'

export enum TasksView {
  QUESTS,
  LEADERBOARD,
}

const UserMenu = () => {
  // const router = useRouter()
  const { t } = useTranslation()
  const { account, error } = useWeb3React()
  const { logout } = useAuth()
  const { hasPendingTransactions, pendingNumber } = usePendingTransactions()
  // const { isInitialized, isLoading, profile } = useProfile()
  // const [onPresentWalletModal] = useModal(<WalletModal initialView={WalletView.WALLET_INFO} />)
  // const [onPresentTransactionModal] = useModal(<WalletModal initialView={WalletView.TRANSACTIONS} />)
  // const [onPresentWrongNetworkModal] = useModal(<WalletModal initialView={WalletView.WRONG_NETWORK} />)
  // const hasProfile = isInitialized && !!profile
  // const avatarSrc = profile?.nft?.image?.thumbnail
  const [userMenuText, setUserMenuText] = useState<string>('')
  const [userMenuVariable, setUserMenuVariable] = useState<UserMenuVariant>('default')
  const isWrongNetwork: boolean = error && error instanceof UnsupportedChainIdError
  const accountEllipsis = account ? `${account.substring(0, 7)}...${account.substring(account.length - 7)}` : null
  // const [view, setView] = useState(WalletView.WALLET_INFO)
  const [view, setView] = useState(TasksView.QUESTS)

  const handleClick = (newIndex: number) => {
    setView(newIndex)
  }

  // const handleOpenTransaction = (e) => {
  //   e?.stopPropagation()
  //   setView(WalletView.TRANSACTIONS)
  // }

  // const handleOpenInfo = (e) => {
  //   e?.stopPropagation()
  //   setView(WalletView.WALLET_INFO)
  // }

  const { balance, fetchStatus } = useGetBnbBalance()
  const { balance: cakeBalance, fetchStatus: cakeFetchStatus } = useTokenBalance(tokens.cake.address)

  useEffect(() => {
    if (hasPendingTransactions) {
      setUserMenuText(t('%num% Pending', { num: pendingNumber }))
      setUserMenuVariable('pending')
    } else {
      setUserMenuText('')
      setUserMenuVariable('default')
    }
  }, [hasPendingTransactions, pendingNumber, t])

  // const onClickWalletMenu = (): void => {
  //   if (isWrongNetwork) {
  //     onPresentWrongNetworkModal()
  //   } else {
  //     onPresentWalletModal()
  //   }
  // }

  const [gradient, setGradient] = useState('')

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF'
    let color = '#'
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)]
    }
    return color
  }

  const getRandomGradient = () => {
    const color1 = getRandomColor()
    const color2 = getRandomColor()
    const color3 = getRandomColor()
    const color4 = getRandomColor()
    return `linear-gradient(45deg, ${color1}, ${color2}, ${color3}, ${color4})`
  }

  useEffect(() => {
    setGradient(getRandomGradient())
  }, [])

  const [walletName, setWalletName] = useState('No wallet.')

  useEffect(() => {
    const detectWalletProvider = () => {
      if (window.ethereum) {
        if (window.ethereum.isMetaMask) {
          setWalletName('Metamask')
        } else if (window.ethereum.isCoinbaseWallet) {
          setWalletName('Coinbase Wallet')
        } else if (window.ethereum.isBitKeep) {
          setWalletName('BitKeep')
        } else if (window.ethereum.isTrust) {
          setWalletName('Trust Wallet')
        } else if (window.ethereum.isBitgetWallet) {
          setWalletName('Bitget')
        } else {
          setWalletName('Unknown wallet.')
        }
      } else {
        console.log('Unknown wallet.')
      }
    }

    detectWalletProvider()
  }, [])

  const chainId = parseInt(process.env.NEXT_PUBLIC_CHAIN_ID, 10) as keyof typeof NETWORK_CONFIG

  const UserMenuItems = ({ toggleDropdownMenu }) => {
    return (
      <>
        <Box
          background="var(--user-info-modal)"
          padding="5px 16px 16px"
          borderRadius="10px"
          boxShadow="4px 4px 17px 0px #0000007D"
          height="100vh"
          overflowY="auto"
        >
          <>
            <Flex flexDirection="column" position="relative">
              <Flex alignItems="center" style={{ gap: '12px' }} padding="16px 0px 20px">
                <Flex alignItems="center" style={{ gap: '8px' }}>
                  <Box minWidth="40px" height="40px" borderRadius="50%" background={gradient} />
                  <Flex flexDirection="column">
                    <Flex style={{ gap: '12px' }}>
                      <Text fontSize={['16px']}>{accountEllipsis}</Text>
                      <FlexGap alignItems="center" gap="8px">
                        <LinkExternal
                          color="transparent"
                          iconWidth="15px"
                          margin={false}
                          href={getBscScanLink(account, 'address')}
                        />
                        <CopyButton
                          width="16px"
                          text={account}
                          tooltipMessage={t('Copied')}
                          tooltipTop={-40}
                          color="white"
                        />
                      </FlexGap>
                    </Flex>
                    <Flex alignItems="center" style={{ gap: '8px' }}>
                      <Text small fontWeight="200">
                        {walletName}
                      </Text>
                      <Box width="5px" height="5px" borderRadius="50%" background="rgba(255, 0, 0, 1)" />
                      <Flex alignItems="center">
                        <Box width="16px" mr="6px">
                          <img src="/images/network-logo.svg" alt="" />
                        </Box>
                        <Text small fontWeight="200">
                          {NETWORK_CONFIG[chainId].name}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
              <IconButton
                variant="text"
                onClick={toggleDropdownMenu}
                ml="auto"
                px="0"
                style={{ width: 'auto', height: 'auto', position: 'absolute', top: '7px', right: '5px' }}
              >
                <CloseIcon color="var(--white)" width="16px" />
              </IconButton>
            </Flex>
            <Box background="var(--white)" p="16px" borderRadius="10px">
              <Box mb="16px">
                {fetchStatus !== FetchStatus.Fetched ? (
                  <Skeleton height="22px" width="60px" />
                ) : (
                  <Flex
                    flexDirection="column"
                    alignItems="center"
                    borderBottom="1px solid var(--black)"
                    p="0 16px 16px"
                  >
                    <Text textAlign="center" color="var(--black)">
                      {ETHER.symbol} balance
                    </Text>
                    <Text
                      fontWeight="600"
                      fontSize={['25px', , , , '40px']}
                      lineHeight="1"
                      textTransform="uppercase"
                      textAlign="center"
                      color="var(--black)"
                    >
                      {formatBigNumber(balance, 3)}
                    </Text>
                  </Flex>
                )}
              </Box>
              <FlexGap flexDirection="column" gap="8px">
                {/* <FlexGap width="100%" justifyContent="space-between" alignItems="center" mb="8px">
                  <FlexGap gap="5px" alignItems="center">
                    <CurrencyLogo currency={ETHER} size="28px" />
                    <Flex flexDirection="column">
                      <Text lineHeight="1" color="var(--black)">
                        {ETHER.symbol}
                      </Text>
                    </Flex>
                  </FlexGap>
                  {fetchStatus !== FetchStatus.Fetched ? (
                    <Skeleton height="22px" width="60px" />
                  ) : (
                    <Text color="var(--black)">{formatBigNumber(balance, 6)}</Text>
                  )}
                </FlexGap> */}
                <FlexGap width="100%" justifyContent="space-between" alignItems="center" mb="8px">
                  <FlexGap gap="5px" alignItems="center">
                    <CurrencyLogo currency={tokens.cake} size="28px" />
                    <Flex flexDirection="column">
                      <Text lineHeight="1" color="var(--black)">
                        {tokens.cake.symbol}
                      </Text>
                    </Flex>
                  </FlexGap>
                  {cakeFetchStatus !== FetchStatus.Fetched ? (
                    <Skeleton height="22px" width="60px" />
                  ) : (
                    <Text color="var(--black)">{getFullDisplayBalance(cakeBalance, 18, 2)}</Text>
                  )}
                </FlexGap>
                <FlexGap width="100%" justifyContent="space-between" alignItems="center" mb="8px">
                  <FlexGap gap="5px" alignItems="center">
                    {/* <CurrencyLogo currency={tokens.cake} size="28px" /> */}
                    <img width={28} src="/images/network-logo.svg" alt="" />
                    <Flex flexDirection="column">
                      <Text lineHeight="1" color="var(--black)">
                        SONEIUM
                      </Text>
                    </Flex>
                  </FlexGap>
                  {cakeFetchStatus !== FetchStatus.Fetched ? (
                    <Skeleton height="22px" width="60px" />
                  ) : (
                    <Text color="var(--black)">0.00</Text>
                  )}
                </FlexGap>
              </FlexGap>
            </Box>

            <Box py="16px">
              <Button width="100%" height="50px" padding="0 8px" onClick={logout}>
                <Text>Disconnect</Text>
              </Button>
            </Box>
          </>

          <Text mb="10px">History</Text>
          <Box background="var(--white)" p="16px" borderRadius="10px" mb="16px" overflowX="hidden">
            <WalletTransactions />
          </Box>

          <Flex flexDirection="column">
            <FlexGap gap="16px" mb="10px">
              <Text
                color={view === TasksView.QUESTS ? 'var(--primary)' : 'var(--white)'}
                fontWeight="700"
                onClick={() => handleClick(TasksView.QUESTS)}
              >
                Quest
              </Text>
              <Text
                color={view === TasksView.LEADERBOARD ? 'var(--primary)' : 'var(--white)'}
                fontWeight="700"
                onClick={() => handleClick(TasksView.LEADERBOARD)}
              >
                Leaderboard
              </Text>
            </FlexGap>

            <Box>
              {view === TasksView.QUESTS ? <TaskQuests /> : null}
              {view === TasksView.LEADERBOARD ? <TaskLeaderboard /> : null}
            </Box>
          </Flex>
        </Box>
      </>
    )
  }

  if (account) {
    return (
      <UIKitUserMenu account={account} text={userMenuText} variant={userMenuVariable}>
        {({ isOpen, toggleDropdownMenu }) => {
          return <>{isOpen ? <UserMenuItems toggleDropdownMenu={toggleDropdownMenu} /> : null}</>
        }}
      </UIKitUserMenu>
    )
  }

  if (isWrongNetwork) {
    return (
      <UserMenuWrapper text={t('Network')} variant="danger">
        {/* {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)} */}
      </UserMenuWrapper>
    )
  }

  return (
    <ConnectWalletButton variant="connect-wallet" height={40}>
      Connect Wallet
      {/* <Box display={['none', , , , 'block']}>
        <Text fontSize={['14px', , , , '18px']}>Connect Wallet</Text>
      </Box>
      <Box display={['block', , , , 'none']}>
        <Text fontSize={['14px', , , , '18px']}>Connect</Text>
      </Box> */}
    </ConnectWalletButton>
  )
}

export default UserMenu
