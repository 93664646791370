import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        stroke="#0D0D0D"
        strokeLinecap="square"
        strokeWidth="1.5"
        d="M12.8 16.625v4.625H2.278V2.75H12.8v4.625M21.727 12.001H7.787M17.129 7.405c0 2.363 2.08 4.596 4.595 4.596M17.129 16.596c0-2.362 2.08-4.595 4.595-4.595"
      />
    </Svg>
  )
}

export default Icon
