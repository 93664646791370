import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 21 18" {...props}>
      <path
        d="M17.2857 12.875V15.2165C17.2857 15.6453 17.1365 16.0566 16.8709 16.3598C16.6054 16.6629 16.2451 16.8333 15.8696 16.8333H9.14286H2.41615C2.04056 16.8333 1.68036 16.6629 1.41478 16.3598C1.1492 16.0566 1 15.6453 1 15.2165V1H8.08074H15.1615V4.23364"
        stroke="white"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M19.9962 7.08008H12.1797L12.1886 13.1596L12.1834 13.1596H19.998L19.9962 13.1596V7.08008Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M17.3933 7.07881V4.03906H13.9194H5.23438" stroke="white" strokeWidth="1.5" strokeLinejoin="round" />
    </Svg>
  )
}

export default Icon
