import styled from 'styled-components'
import { StyledMenuItemProps } from './types'

export const StyledMenuItemContainer = styled.div<StyledMenuItemProps>`
  position: relative;
  /* background-color: ${({ theme, $isActive, $isMobile }) =>
    !$isActive ? 'transparent' : $isMobile ? theme.colors.pink : theme.colors.pinkDis};
  border-left: ${({ $isActive, $isMobile }) => ($isActive && !$isMobile ? '2px solid var(--primary)' : 'none')};
  border-radius: ${({ $isActive, $isMobile }) => ($isActive && !$isMobile ? '0 8px 8px 0' : '8px')}; */

  ${({ $isActive, $variant, theme }) =>
    $isActive &&
    // $variant === 'subMenu' &&
    `
      &:after{
        content: "";
        position: absolute;
        bottom: 0;
        height: 3px;
        width: 100%;
        background-color: white;
        box-shadow: 0px 0px 6px 0px rgba(34, 36, 38, 1);
        border-radius: 2px 2px 0 0;
      }
    `};
`

const StyledMenuItem = styled.a<StyledMenuItemProps>`
  position: relative;
  display: flex;
  align-items: center;
  color: black;
  font-size: 16px;
  font-weight: ${({ $isActive }) => ($isActive ? '600' : '400')};
  font-family: 'Poppins Medium';
  pointer-events: ${({ $isComming }) => ($isComming ? 'none' : 'unset')};
  cursor: ${({ $isComming }) => ($isComming ? 'none' : 'pointer')};

  ${({ $statusColor, theme }) =>
    $statusColor &&
    `

    &:after {
      content: "";
      border-radius: 100%;
      background: ${theme.colors[$statusColor]};
      height: 8px;
      width: 8px;
      margin-left: 12px;

    }
  `}

  ${({ $variant }) =>
    $variant === 'default'
      ? `
    padding: 0 16px;
    height: 48px;
  `
      : `
    padding: 4px 4px 0px 4px;
    height: 42px;
  `}

  &:hover {
    ${({ $variant }) => $variant === 'default' && 'border-radius: 16px;'};
  }
`

export default StyledMenuItem
