import { useDispatch } from 'react-redux'
import { Box, Button, Flex, Text } from 'packages/uikit'
import { AppDispatch } from 'state'
import { isTransactionRecent, useAllTransactions } from 'state/transactions/hooks'
import { useTranslation } from 'contexts/Localization'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { clearAllTransactions } from 'state/transactions/actions'
import orderBy from 'lodash/orderBy'
import TransactionRow from './TransactionRow'

const WalletTransactions: React.FC = () => {
  const { chainId } = useActiveWeb3React()
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation()
  const allTransactions = useAllTransactions()
  const sortedTransactions = orderBy(Object.values(allTransactions).filter(isTransactionRecent), 'addedTime', 'desc')

  const handleClearAll = () => {
    if (chainId) {
      dispatch(clearAllTransactions({ chainId }))
    }
  }

  return (
    <Box>
      {sortedTransactions.length > 0 && (
        <Flex alignItems="center" justifyContent="flex-end" mb="16px">
          {/* <Text color="black">{t('Recent Transactions')}</Text> */}
          <Button scale="sm" onClick={handleClearAll} px="0" minWidth="79px">
            <Text fontSize="12px">{t('Clear all')}</Text>
          </Button>
        </Flex>
      )}
      {sortedTransactions.length > 0 ? (
        <Box maxHeight="250px" overflowY="auto">
          {sortedTransactions.map((txn) => (
            <TransactionRow key={txn.hash} txn={txn} />
          ))}
        </Box>
      ) : (
        <Text fontSize="18px" color="black" textAlign="center">
          {t('No recent transactions')}
        </Text>
      )}
    </Box>
  )
}

export default WalletTransactions
