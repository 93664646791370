import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 18">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.90185 7.75631C7.25377 5.32635 10.8226 3.72437 12.6082 2.95037C17.7066 0.740448 18.766 0.356561 19.4565 0.343885C19.6084 0.341097 19.9479 0.38032 20.1679 0.56632C20.3536 0.723374 20.4047 0.935532 20.4292 1.08444C20.4536 1.23334 20.4841 1.57255 20.4599 1.8376C20.1836 4.86281 18.9881 12.2042 18.3799 15.5925C18.1226 17.0262 17.6159 17.5069 17.1253 17.554C16.0592 17.6562 15.2496 16.8197 14.217 16.1143C12.6012 15.0105 11.6884 14.3234 10.12 13.2463C8.3074 12.0016 9.48242 11.3174 10.5154 10.1993C10.7857 9.90672 15.4831 5.45414 15.574 5.05022C15.5854 4.99971 15.5959 4.8114 15.4886 4.71197C15.3812 4.61254 15.2228 4.64654 15.1085 4.67358C14.9464 4.71192 12.3652 6.4899 7.36469 10.0075C6.63201 10.5318 5.96837 10.7873 5.37377 10.7739C4.71827 10.7592 3.45735 10.3877 2.51999 10.0701C1.37028 9.68067 0.456511 9.47476 0.53608 8.81332C0.577524 8.4688 1.03278 8.11647 1.90185 7.75631Z"
        fill="white"
      />
    </Svg>
  )
}

export default Icon
