import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 23 17">
      <path
        d="M19.0373 1.39183C17.651 0.755747 16.1645 0.287111 14.6102 0.0187041C14.5819 0.0135239 14.5536 0.0264694 14.539 0.0523611C14.3478 0.3924 14.136 0.836008 13.9877 1.18468C12.316 0.934404 10.6528 0.934404 9.01535 1.18468C8.86702 0.828258 8.64756 0.3924 8.45552 0.0523611C8.44094 0.0273333 8.41266 0.0143878 8.38436 0.0187041C6.83092 0.286254 5.34435 0.75489 3.95722 1.39183C3.94521 1.397 3.93492 1.40564 3.92809 1.41686C1.10839 5.62943 0.335951 9.73847 0.714882 13.7966C0.716597 13.8164 0.727741 13.8354 0.743173 13.8475C2.60353 15.2137 4.40561 16.0431 6.17422 16.5928C6.20253 16.6015 6.23252 16.5911 6.25053 16.5678C6.6689 15.9965 7.04183 15.3941 7.36159 14.7606C7.38046 14.7235 7.36244 14.6795 7.32388 14.6648C6.73234 14.4404 6.16908 14.1668 5.62725 13.8561C5.5844 13.8311 5.58097 13.7698 5.62039 13.7404C5.73441 13.655 5.84846 13.5661 5.95733 13.4763C5.97703 13.46 6.00448 13.4565 6.02764 13.4668C9.58716 15.092 13.4408 15.092 16.9583 13.4668C16.9815 13.4556 17.0089 13.4591 17.0295 13.4755C17.1384 13.5652 17.2524 13.655 17.3673 13.7404C17.4067 13.7698 17.4041 13.8311 17.3613 13.8561C16.8194 14.1728 16.2562 14.4404 15.6638 14.6639C15.6252 14.6786 15.6081 14.7235 15.6269 14.7606C15.9535 15.3932 16.3265 15.9956 16.7371 16.567C16.7543 16.5911 16.7851 16.6015 16.8134 16.5928C18.5906 16.0431 20.3927 15.2137 22.253 13.8475C22.2693 13.8354 22.2796 13.8173 22.2814 13.7974C22.7349 9.10582 21.5218 5.03048 19.0656 1.41771C19.0596 1.40564 19.0493 1.397 19.0373 1.39183ZM7.89315 11.3256C6.82148 11.3256 5.93846 10.3417 5.93846 9.13345C5.93846 7.92516 6.80436 6.94129 7.89315 6.94129C8.99048 6.94129 9.86495 7.9338 9.8478 9.13345C9.8478 10.3417 8.9819 11.3256 7.89315 11.3256ZM15.1202 11.3256C14.0486 11.3256 13.1656 10.3417 13.1656 9.13345C13.1656 7.92516 14.0315 6.94129 15.1202 6.94129C16.2176 6.94129 17.092 7.9338 17.0749 9.13345C17.0749 10.3417 16.2176 11.3256 15.1202 11.3256Z"
        fill="white"
      />
    </Svg>
  )
}

export default Icon
