import { scales, variants } from './types'

export const scaleVariants = {
  [scales.MD]: {
    height: '52px',
    padding: '0 24px',
  },
  [scales.SM]: {
    height: '32px',
    padding: '0 16px',
  },
  [scales.XS]: {
    height: '20px',
    fontSize: '12px',
    padding: '0 8px',
  },
}

export const styleVariants = {
  [variants.PRIMARY]: {
    background: 'linear-gradient(180deg, #C17CCF 0%, #792B97 100%)',
    color: 'white',
    fontSize: ['20px'],
    fontWeight: '700',
    borderRadius: '25px',
  },
  [variants.SECONDARY]: {
    backgroundColor: 'transparent',
    border: '2px solid',
    borderColor: 'primary',
    fontSize: ['20px'],
    fontWeight: '700',
    borderRadius: '25px',
    boxShadow: 'none',
    color: 'white',
    ':disabled': {
      backgroundColor: 'transparent',
    },
  },
  [variants.TERTIARY]: {
    backgroundColor: 'tertiary',
    boxShadow: 'none',
    color: 'primary',
  },
  [variants.SUBTLE]: {
    backgroundColor: '#222426',
    color: 'backgroundAlt',
  },
  [variants.DANGER]: {
    backgroundColor: 'failure',
    color: 'white',
  },
  [variants.SUCCESS]: {
    backgroundColor: 'success',
    color: 'white',
  },
  [variants.TEXT]: {
    backgroundColor: 'transparent',
    color: 'primary',
    boxShadow: 'none',
  },
  [variants.LIGHT]: {
    backgroundColor: 'input',
    color: 'textSubtle',
    boxShadow: 'none',
  },
  [variants.BLASTBTN]: {
    backgroundColor: 'var(--primary)',
    color: 'white',
    boxShadow: 'none',
  },
  [variants.BLASTDISABLED]: {
    backgroundColor: '#AFAFAF',
    color: '#000000',
    boxShadow: 'none',
  },
  [variants.PINK]: {
    backgroundColor: '#FDCEE5',
    color: '#F988BF',
    boxShadow: 'none',
  },
  [variants.SLIPP]: {
    backgroundColor: 'pinkDis',
    color: '#000000',
    boxShadow: 'none',
  },
  [variants.INACTIVE]: {
    backgroundColor: 'transparent',
    color: 'pink',
    boxShadow: 'none',
  },
  [variants.CANCEL]: {
    background: 'linear-gradient(180deg, #D9D9D9 0%, #737373 100%)',
    color: 'white',
    boxShadow: 'none',
    fontSize: ['20px'],
    fontWeight: '700',
    borderRadius: '25px',
  },
  [variants.CONNECT_WALLET]: {
    background: 'linear-gradient(180deg, #C17CCF 0%, #792B97 100%)',
    color: 'white',
    fontSize: ['20px'],
    fontWeight: '400',
    borderRadius: '10px',
    border: '2px solid var(--white)',
  },
}
