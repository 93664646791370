import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from 'packages/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  :root {
    // colors
    --primary: ${({ theme }) => theme.colors.primary};
    --primary-dark: ${({ theme }) => theme.colors.primaryDark};
    --primary-blue: ${({ theme }) => theme.colors.blue};
    --user-info-modal: rgba(21, 20, 28, 1);
    --gradients-button: ${({ theme }) => theme.colors.gradients.button};
    --gradients-box: linear-gradient(270deg, #C17CCF 0%, #792B97 100%);
    --black: rgba(0, 0, 0, 1);
    --white: rgba(255, 255, 255 , 1);
    --white-50: rgba(255, 255, 255 , 0.5);
    --warning: rgba(255, 193, 7, 1);
  }
  * {
    font-family: 'Kanit', sans-serif;
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle
