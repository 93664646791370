export const fetchListFromAPI = async (api: string) => {
  try {
    const response = await fetch(api)
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error('error:', error)
    return null
  }
}

export const fetchDataFromAPIWithParams = async (api: string, params: any) => {
  const queryParams = new URLSearchParams(params).toString()
  const urlWithParams = `${api}?${queryParams}`

  try {
    const response = await fetch(urlWithParams)
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }

    return response.json()
  } catch (error) {
    console.error('error:', error)
    return null
  }
}

export const createData = async (api: string, data: any) => {
  try {
    const response = await fetch(api, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }

    return response.json()
  } catch (error) {
    console.error('error:', error)
    return null
  }
}

export const mergeArrays = (arr = []) => {
  let i
  let obj = {}
  const newArr = []

  for (i = 0; i < arr.length; i++) {
    const totalXp: any = arr[i].amount * 10000
    obj = { rank: i + 1, address: arr[i].userAddress, amount: arr[i].amount, point: totalXp }
    newArr.push(obj)
  }
  return newArr
}
