/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createElement, memo } from 'react'
import styled from 'styled-components'
import { Flex } from '../Box'
import isTouchDevice from '../../util/isTouchDevice'
import DropdownMenu from '../DropdownMenu/DropdownMenu'
import MenuItem from '../MenuItem/MenuItem'
import { MenuItemsProps } from './types'
import { Text } from '../Text'
// import AnimatedIconComponent from '../Svg/AnimatedIconComponent'

const StyledText = styled(Text)<{ isActive?: boolean }>`
  position: relative;
`

const StyledIconWrapper = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  margin-right: 8px;
`

const SoonLabel = styled(Text)`
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(calc(100% + 5px), -50%);
`

const MenuItems: React.FC<MenuItemsProps> = ({ items = [], activeItem, activeSubItem, ...props }) => {
  return (
    <Flex width="100%" {...props}>
      {items.map(({ label, items: menuItems = [], href, icon, fillIcon, commingSoon }, i) => {
        const statusColor = menuItems?.find((menuItem) => menuItem.status !== undefined)?.status?.color
        const isActive = activeItem === href
        const linkProps = isTouchDevice() && menuItems && menuItems.length > 0 ? {} : { href }
        const Icon = icon
        return (
          <DropdownMenu key={`${label}#${href}`} items={menuItems} py={1} activeItem={activeSubItem}>
            <MenuItem {...linkProps} isActive={isActive} statusColor={statusColor} isComming={commingSoon} key={i}>
              {/* {icon && (
              <StyledIconWrapper isActive={isActive}>
                {icon && createElement(Icon as any, { width: '26px', height: '26px', color: 'transparent' })}
              </StyledIconWrapper>
            )} */}
              <StyledText isActive={isActive}>
                {label}

                <SoonLabel fontSize="12px" color="primary">
                  {commingSoon ? 'Soon' : null}
                </SoonLabel>
              </StyledText>
            </MenuItem>
          </DropdownMenu>
        )
      })}
    </Flex>
  )
}

export default memo(MenuItems)
