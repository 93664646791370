import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none" viewBox="0 0 24 25">
      <g
        stroke="#0D0D0D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        clipPath="url(#clip0_398_1305)"
      >
        <path d="M3 12.752a9 9 0 0116-5.657M19.5 3.752v4h-4M21 12.752a9 9 0 01-16 5.657" />
        <path d="M4.5 21.752v-4h4" />
      </g>
      <defs>
        <clipPath id="clip0_398_1305">
          <path fill="#fff" d="M0 0H24V24H0z" transform="translate(0 .752)" />
        </clipPath>
      </defs>
    </Svg>
  )
}

export default Icon
