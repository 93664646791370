import React, { useEffect, useState } from 'react'
import { usePopper } from 'react-popper'
import styled from 'styled-components'
import { Box, Flex } from '../../../../components/Box'
// import { ChevronDownIcon } from '../../../../components/Svg'
import { UserMenuProps, variants } from './types'
import MenuIcon from './MenuIcon'
import { UserMenuItem } from './styles'
// import useOnClickOutside from '../../../../hooks/useOnClickOutside'

export const StyledUserMenu = styled(Flex)`
  align-items: center;
  background: var(--gradients-button);
  border-radius: 10px;
  cursor: pointer;
  display: inline-flex;
  height: 36px;
  padding-left: 40px;
  padding-right: 8px;
  position: relative;

  border: 2px solid var(--white);

  &:hover {
    opacity: 0.65;
  }
`

export const LabelText = styled.div`
  /* color: var(--white); */
  display: none;
  font-weight: 400;

  background: linear-gradient(180deg, #c17ccf 0%, #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  ${({ theme }) => theme.mediaQueries.sm} {
    display: block;
    margin-left: 8px;
    margin-right: 4px;
  }
`

const Backdrop = styled.div`
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(217, 217, 217, 0.5);
`

const Menu = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;

  border-radius: 8px;
  pointer-events: auto;
  visibility: visible;
  z-index: 9999;

  width: 100%;
  max-width: 465px;
  height: 100vh;
  overflow-y: auto;
  background: var(--user-info-modal);

  /* &::after {
    content: '';
    position: fixed;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(217, 217, 217, 0.5);
  } */

  ${({ isOpen }) =>
    !isOpen &&
    `
    pointer-events: none;
    visibility: hidden;
  `}

  ${UserMenuItem}:first-child {
    border-radius: 8px 8px 0 0;
  }

  ${UserMenuItem}:last-child {
    border-radius: 0 0 8px 8px;
  }
`

const UserMenu: React.FC<UserMenuProps> = ({
  account,
  text,
  avatarSrc,
  variant = variants.DEFAULT,
  children,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [targetRef, setTargetRef] = useState<HTMLDivElement | null>(null)
  const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null)
  const accountEllipsis = account ? `${account.substring(0, 8)}...${account.substring(account.length - 7)}` : null
  const { styles, attributes } = usePopper(targetRef, tooltipRef, {
    strategy: 'fixed',
    placement: 'bottom-end',
    modifiers: [{ name: 'offset', options: { offset: [0, 0] } }],
  })

  // useEffect(() => {
  //   const showDropdownMenu = () => {
  //     setIsOpen(true)
  //   }

  //   const hideDropdownMenu = (evt: MouseEvent | TouchEvent) => {
  //     const target = evt.target as Node
  //     if (target && !tooltipRef?.contains(target)) {
  //       setIsOpen(false)
  //       evt.stopPropagation()
  //     }
  //   }

  //   targetRef?.addEventListener('mouseenter', showDropdownMenu)
  //   targetRef?.addEventListener('mouseleave', hideDropdownMenu)

  //   return () => {
  //     targetRef?.removeEventListener('mouseenter', showDropdownMenu)
  //     targetRef?.removeEventListener('mouseleave', hideDropdownMenu)
  //   }
  // }, [targetRef, tooltipRef, setIsOpen])

  const toggleDropdownMenu = () => setIsOpen((s) => !s)

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = `hidden`
    } else {
      document.body.style.overflow = `auto`
    }
  }, [isOpen])

  // useOnClickOutside(
  //   {
  //     current: targetRef,
  //   },
  //   () => setIsOpen(false),
  // )

  return (
    <Flex alignItems="center" height="100%" ref={setTargetRef} {...props}>
      {isOpen ? (
        <Backdrop
          onClick={() => {
            setIsOpen((s) => !s)
          }}
        />
      ) : null}
      <StyledUserMenu
        onClick={() => {
          setIsOpen((s) => !s)
        }}
      >
        <MenuIcon avatarSrc={avatarSrc} variant={variant} />
        <LabelText title={text || account}>{text || accountEllipsis}</LabelText>
        {/* <ChevronDownIcon color="white" width="24px" /> */}
      </StyledUserMenu>
      <Menu
        // style={styles.popper}
        // ref={setTooltipRef}
        // {...attributes.popper}
        isOpen={isOpen}
      >
        <Box>{children?.({ isOpen, toggleDropdownMenu })}</Box>
      </Menu>
    </Flex>
  )
}

export default UserMenu
