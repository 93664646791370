import { Box, Button, Flex, InjectedModalProps, LinkExternal, Message, Skeleton, Text } from 'packages/uikit'
import { useWeb3React } from '@web3-react/core'
import tokens from 'config/constants/tokens'
import { FetchStatus } from 'config/constants/types'
import { useTranslation } from 'contexts/Localization'
import useAuth from 'hooks/useAuth'
import useTokenBalance, { useGetBnbBalance } from 'hooks/useTokenBalance'

import { getBscScanLink } from 'utils'
import { formatBigNumber, getFullDisplayBalance } from 'utils/formatBalance'
import CopyAddress from './CopyAddress'

interface WalletInfoProps {
  hasLowBnbBalance: boolean
  onDismiss: InjectedModalProps['onDismiss']
}

const WalletInfo: React.FC<WalletInfoProps> = ({ hasLowBnbBalance, onDismiss }) => {
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const { balance, fetchStatus } = useGetBnbBalance()
  const { balance: cakeBalance, fetchStatus: cakeFetchStatus } = useTokenBalance(tokens.cake.address)
  const { logout } = useAuth()

  const handleLogout = () => {
    onDismiss?.()
    logout()
  }

  return (
    <>
      <Text color="black" fontWeight="bold" mb="20px">
        {t('Your Address')}
      </Text>
      <Box background="rgba(253, 206, 229, 1)" borderRadius="8px" padding="0 12px" mb="24px">
        <CopyAddress account={account} />
      </Box>
      {hasLowBnbBalance && (
        <Message variant="warning" mb="24px">
          <Box>
            <Text color="black" fontWeight="bold">
              {t('ETH Balance Low')}
            </Text>
            <Text color="black" as="p">
              {t('You need ETH for transaction fees.')}
            </Text>
          </Box>
        </Message>
      )}
      <Flex mb="20px" alignItems="center" justifyContent="space-between">
        <Text color="black">{t('ETH Balance')}</Text>
        {fetchStatus !== FetchStatus.Fetched ? (
          <Skeleton height="22px" width="60px" />
        ) : (
          <Text color="black">{formatBigNumber(balance, 6)}</Text>
        )}
      </Flex>
      <Flex alignItems="center" justifyContent="space-between" mb="24px">
        <Text color="black">{t('SONE Balance')}</Text>
        {cakeFetchStatus !== FetchStatus.Fetched ? (
          <Skeleton height="22px" width="60px" />
        ) : (
          <Text color="black">{getFullDisplayBalance(cakeBalance, 18, 3)}</Text>
        )}
      </Flex>
      <Flex alignItems="center" justifyContent="start" mb="24px">
        <LinkExternal color="var(--primary)" href={getBscScanLink(account, 'address')}>
          <Text color="var(--primary)">{t('View on Explorer')}</Text>
        </LinkExternal>
      </Flex>
      <Button style={{ border: '1px solid var(--primary)', color: 'white' }} width="100%" onClick={handleLogout}>
        {t('Disconnect Wallet')}
      </Button>
    </>
  )
}

export default WalletInfo
