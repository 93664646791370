import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 22">
      <path
        d="M17.6875 5.96875C17.6875 8.19531 14.3281 9.875 9.875 9.875C5.42188 9.875 2.0625 8.19531 2.0625 5.96875C2.0625 3.74219 5.42188 2.0625 9.875 2.0625C14.3281 2.0625 17.6875 3.74219 17.6875 5.96875ZM11.4375 17.6875C8.11719 17.6875 5.28125 16.8281 3.625 15.3828C3.67188 17.5937 7.00781 19.25 11.4375 19.25C15.8672 19.25 19.1797 17.6016 19.25 15.4141C17.6172 16.8281 14.8516 17.6875 11.4375 17.6875ZM9.875 14.5625C7.86492 14.6046 5.8677 14.2323 4.00781 13.4688C5.00781 15.0469 7.86719 16.125 11.4375 16.125C15.8906 16.125 19.25 14.4453 19.25 12.2188C19.2436 11.7356 19.0881 11.2663 18.8047 10.875C17.6406 13.1094 14.2734 14.5625 9.875 14.5625ZM10.6562 11.4375H9.875H9.09375C6.03125 11.3203 3.53906 10.4844 2.0625 9.17969C2.13281 11.3516 5.46875 13 9.875 13C14.2812 13 17.6172 11.3516 17.6875 9.16406C16.1797 10.4688 13.6875 11.3047 10.6562 11.4375Z"
        fill="#C17CCF"
      />
    </Svg>
  )
}

export default Icon
